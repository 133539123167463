<template>
    <div>
        <h2>{{ $t("check.check_deposit") }}</h2>
        <div class="row">
            <ShutterPanel ref="shutterPanel" />
        </div>
    </div>
</template>


<script type="text/javascript">
    import ShutterPanel from "GroomyRoot/components/Utils/ShutterPanel"
    import Navigation from "@/mixins/Navigation.js"
    import Payment from "@/mixins/Payment.js"
    import Shutter from "@/mixins/Shutter.js"

    export default {
        name: "list",
        mixins: [Navigation, Payment, Shutter],
        props: [],
        data () {
            return {
                deselectLabel: this.getTrad("global.press_enter_to_remove"),
                selectedLabel: this.getTrad("global.selected_label"),
                enter_to_select: this.getTrad("global.press_enter_to_select"),
                labelTitleAccoutingPlan: this.getTrad("compta.select_accounting_plan"),
                labelTitleConfiguration: this.getTrad("check.select_configuration"),
                check_deposit_status: this.getConfig('check_deposit_status'),
                check_deposit_status_format: [],
                check_deposit: null,
                check_deposit_formatted: null,
                
                table_busy: true,
                modal_loaded: false,
                ready: false,
                processing: false,
                isLoading: false,
                isLoadingConfiguration: false,
                all_checks_loading: false,

                accounting_plans: [],
                configurations: [],
                errors: [],
                all_checks: [],
                checked_checks: [],
                
                invoice_ids: null,
                invoice_nums: null,
				config_table_hrefs: {
                    'payment.tiers.tiers_rs': {
                        routeUniqueName: 'tiersFiche',
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'payment.tiers.tiers_id'
                        }
                    }
                },
                events_tab: {
                    'TableAction::goToAddCheckDeposit': this.addCheckDepositFormShutter,
                    'TableAction::goToPrintCheckDeposit': (params) => {
                        this.printCheckDeposit(params)
                    }
				},
				start_date: new Date(new Date().setMonth(new Date().getMonth() - 3)),
                end_date: new Date(),
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.shutterPanel().open(this.shutters['check-deposit-list'])
            },

            addCheckDepositFormShutter(){
                this.shutterPanel().open(this.shutters['check-deposit-edit'])
            },

            editCompleted(params){
                this.shutterPanel().open(this.shutters['check-deposit-config'])
                this.shutterPanel().update('check-deposit-config', 'paiements', params.params.payment_id.split(','))
                this.shutterPanel().update('check-deposit-config', 'form', params.form)
            },

            addCheckDepositCompleted(params) {
                this.shutterPanel().keepFirst()
                this.shutterPanel().open(this.shutters['check-deposit-list'])
                this.shutterPanel().update('check-deposit-list')
            },
           
        },
        computed: {
            
            shutters() {
                return {
                    'check-deposit-list': {
                        name: 'check-deposit-list',
                        title: this.$t('check.list_check_deposit'),
                        cmpPath: 'components/Payment/CheckDepositList',
                        props: {
                        }
                    },
                    'check-deposit-edit': {
                        name: 'check-deposit-edit',
                        title: this.$t('check.liste_cheques'),
                        cmpPath: 'components/Payment/CheckDepositForm',
                        props: {
                        },
                        onOk: this.editCompleted,
                    },
                    'check-deposit-config': {
                        name: 'check-deposit-config',
                        title: this.$t('check.config_remise'),
                        cmpPath: 'components/Payment/CheckDepositFormConfig',
                        props: {
                            paiements: null
                        },
                        onOk: this.addCheckDepositCompleted,
                    },
                }
            }
        },
        watch: {
            
        },
        components: {
            ShutterPanel
        }
    }
</script>
